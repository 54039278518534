import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as CryptoJS from 'crypto-js';
import { CookieService } from 'ngx-cookie-service';
const jwtHelper = new JwtHelperService();
// import { environment } from '../../../../environments/environment';

const environment = { envName: 'development' };

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  secretKey = 'bracWashProject2023#DevelopedBy@BMQA@2023_TonmoyrUdra';

  constructor(private cookieService: CookieService) {}

  encrypt(value: string): string {
    //return value;
    if (environment.envName === 'development') {
      return value;
    } else {
      return CryptoJS.AES.encrypt(value, this.secretKey.trim()).toString();
    }
  }

  decrypt(textToDecrypt: string) {
    if (environment.envName === 'development') {
      return textToDecrypt;
    } else {
      if (textToDecrypt === null) {
        return textToDecrypt;
      } else {
        return CryptoJS.AES.decrypt(
          textToDecrypt,
          this.secretKey.trim()
        ).toString(CryptoJS.enc.Utf8);
      }
    }
  }

  setNgoInLocalStorage(ngo: any) {
    localStorage.setItem(
      'current_selected_ngo',
      this.encrypt(JSON.stringify(ngo))
    );
  }

  getNgoInLocalStorage() {
    return JSON.parse(
      this.decrypt(
        localStorage.getItem('current_selected_ngo') as string
      ) as string
    );
  }

  setProjectInLocalStorage(program: any) {
    localStorage.setItem(
      'current_selected_project',
      this.encrypt(JSON.stringify(program))
    );
  }

  getProjectFromLocalStorage() {
    return JSON.parse(
      this.decrypt(
        localStorage.getItem('current_selected_project') as string
      ) as string
    );
  }

  deleteProjectFromLocalStorage() {
    this.clearLocalStorageByKey('current_selected_project');
  }

  getToken() {
    return this.cookieService.get('token');
    // return localStorage.getItem('token');
  }

  setToken(token: string) {
    this.cookieService.set('token', token);
    //this.cookieService.set('token2', token, null, '../',);
    // localStorage.setItem('token', token);
  }

  deleteToken() {
    this.cookieService.delete('token');
  }

  setSessionUserFromToken(token: string) {
    try {
      const tokenDecode = jwtHelper.decodeToken(token);
      delete tokenDecode.iat;
      delete tokenDecode.iss;
      localStorage.setItem('sessionUser', JSON.stringify(tokenDecode));
      return true;
    } catch (error) {
      return false;
    }
  }

  setSessionUser(user: any) {
    localStorage.setItem('sessionUser', this.encrypt(JSON.stringify(user)));
  }

  getSessionUser() {
    return JSON.parse(
      this.decrypt(localStorage.getItem('sessionUser') as string)
    );
  }

  public getSessionUserRole() {
    return this.getSessionUser().role;
  }

  public getIsNgoAdmin(): boolean {
    const role = this.getSessionUserRole();
    return (
      this.getSessionUserRole().short_form === 'admin' ||
      this.getSessionUserRole().short_form === 'donor'
    );
  }

  public getIsSuperAdmin(): boolean {
    const role = this.getSessionUserRole();
    return this.getSessionUserRole().short_form === 'system_admin';
  }

  public clearSessionUser() {
    this.clearLocalStorageByKey('sessionUser');
    this.clearLocalStorageByKey('current_selected_ngo');
    this.clearLocalStorageByKey('current_selected_project');
  }

  setBracToken(token: string) {
    localStorage.setItem('multipass', JSON.stringify(token));
  }

  getBracToken() {
    return JSON.parse(localStorage.getItem('multipass')!);
  }

  getUserInfo() {
    return JSON.parse(localStorage.getItem('user')!);
  }

  setUserInfo(user: string) {
    localStorage.setItem('user', JSON.stringify(user));
  }

  getACLMenuInfo() {
    return JSON.parse(this.decrypt(localStorage.getItem('acl') as string));
  }

  setACLMenuInfo(data: any) {
    localStorage.setItem('acl', this.encrypt(JSON.stringify(data)));
  }

  getControls() {
    return JSON.parse(this.decrypt(localStorage.getItem('controls') as string));
  }

  setControls(data: any) {
    localStorage.setItem('controls', this.encrypt(JSON.stringify(data)));
  }

  getSections() {
    return JSON.parse(this.decrypt(localStorage.getItem('sections') as string));
  }

  setSection(data: any) {
    localStorage.setItem('sections', this.encrypt(JSON.stringify(data)));
  }

  getSelectedMenuInfo() {
    return JSON.parse(
      this.decrypt(localStorage.getItem('selectedMenu') as string)
    );
  }

  setSelectedMenuInfo(data: any) {
    localStorage.setItem('selectedMenu', this.encrypt(JSON.stringify(data)));
  }

  public setSignedUpEmail(email: string) {
    this.setLocalStorage('signedUpEmail', email);
  }

  public getSignedUpEmail() {
    return this.getLocalStorage('signedUpEmail');
  }

  public clearSignedUpEmail() {
    this.clearLocalStorageByKey('signedUpEmail');
  }

  public setResendOtpDisabledTimestamp() {
    const now = new Date().getTime();
    this.setLocalStorage('resendOtpDisabledTimestamp', now);
  }

  public getResendOtpDisabledTimestamp() {
    return this.getLocalStorage('resendOtpDisabledTimestamp');
  }

  public clearResendOtpDisabledTimestamp() {
    this.clearLocalStorageByKey('resendOtpDisabledTimestamp');
  }

  public setLocalStorage(key: string, data: any) {
    try {
      const encryptedData = this.encrypt(JSON.stringify(data));
      localStorage.setItem(key, encryptedData);
    } catch (error) {
      console.error(
        `Failed to set item in localStorage for key: ${key}`,
        error
      );
    }
  }

  public getLocalStorage(key: string) {
    try {
      const storedData = localStorage.getItem(key);
      if (storedData === null) {
        return '';
      }

      const decryptedData = this.decrypt(storedData);
      return JSON.parse(decryptedData);
    } catch (error) {
      console.error(
        `Failed to get and parse item from localStorage for key: ${key}`,
        error
      );
      return '';
    }
  }

  public clearLocalStorageByKey(key: string) {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      console.error(
        `Failed to remove item from localStorage for key: ${key}`,
        error
      );
    }
  }
}
