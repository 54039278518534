import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DevExtremeModule } from 'devextreme-angular';
import { NewsArticleCardsComponent } from '../components/news-article-cards/news-article-cards.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
// import { NullWithDefaultPipe } from '../pipe/null-with-default.pipe';

// function HttpLoaderFactory(http: HttpBackend) {
//   return new TranslateHttpLoader(new HttpClient(http));
// }

// const I18N_CONFIG = {
//   // defaultLanguage: 'english',
//   loader: {
//     provide: TranslateLoader,
//     useFactory: HttpLoaderFactory,
//     // deps: [HttpClient],
//     deps: [HttpBackend],
//   },
// };

@NgModule({
  imports: [
    DevExtremeModule,
    RouterModule,
    CommonModule,
    // TranslateModule.forRoot(I18N_CONFIG),
  ],
  declarations: [NewsArticleCardsComponent],
  exports: [NewsArticleCardsComponent],
})
export class SharedModule {}
