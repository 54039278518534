import { Injectable } from '@angular/core';
import { map, Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UrlEnum } from '../../enum/url.enum';
import { HttpRequestService } from './../http-request/http-request.service';

@Injectable({
  providedIn: 'root',
})
export class ShohayBlogService {
  private blogUrl = `${UrlEnum.SHOHAY_BLOG}/news-articles`;
  private getAllUrl =
    'fields[0]=title&fields[1]=topic&fields[2]=blog_type&fields[3]=createdAt&fields[4]=news_article_id&populate[cover_photo][fields][0]=url&sort[0]=createdAt:desc';
  private getByIdUrl =
    'populate[0]=cover_photo&populate[1]=content&populate[2]=tags&populate[3]=table_of_contents&populate[4]=table_of_contents.content_image&populate[5]=comments&populate[6]=comments.user_profile_img';

  constructor(private httpRequestService: HttpRequestService) {}

  public getArticleNews() {
    const headers = { Authorization: `Bearer ${environment.shohayBlogToken}` };
    return this.httpRequestService
      .get(`${this.blogUrl}?${this.getAllUrl}`, {
        skipAuth: true,
        headers,
      })
      .pipe(
        map((x: any) =>
          x.data.map((item: any) => {
            const { cover_photo, ...restOfAttributes } = item?.attributes;
            return this.restructureArticleItem(item);
          })
        )
      );
  }

  private restructureArticleItem(item: any): any {
    const { cover_photo, ...restOfAttributes } = item?.attributes;
    const body = { ...restOfAttributes };

    body['id'] = item.id;
    body['cover_photo'] = `${
      UrlEnum.SHOHAY_STRAPI
    }${cover_photo?.data?.attributes?.url?.replace(/^\/uploads/, '')}`;

    return body;
  }

  public getArticleById(id: string): Observable<any> {
    const headers = { Authorization: `Bearer ${environment.shohayBlogToken}` };
    return this.httpRequestService
      .get(`${this.blogUrl}/${id}?${this.getByIdUrl}`, {
        skipAuth: true,
        headers: headers,
      })
      .pipe(
        map((x: any) => {
          return this.restructureArticleBody(x);
        })
      );
  }

  private restructureArticleBody(x: any): any {
    const {
      cover_photo,
      comments,
      table_of_contents,
      tags,
      ...restOfAttributes
    } = x?.data?.attributes;

    const body = { ...restOfAttributes };
    body['id'] = x?.data?.id;

    body['cover_photo'] = `${
      UrlEnum.SHOHAY_STRAPI
    }${cover_photo?.data?.attributes?.url?.replace(/^\/uploads/, '')}`;

    body['tags'] = tags
      ?.sort((a, b) => a.id - b.id)
      .map((tag: any) => tag.tag_name);

    body['table_of_contents'] = table_of_contents
      ?.sort((a, b) => a.content_order - b.content_order)
      .map((content: any) => {
        return this.restructureTableOfContents(content);
      });

    body['comments'] =
      comments?.data?.length > 0
        ? comments?.data
            ?.map((comment: any) => {
              return this.restructureComments(comment);
            })
            .sort((a, b) => a.createdAt - b.createdAt)
        : null;
    return body;
  }

  private restructureTableOfContents(content: any): any {
    const { content_image, ...restOfContentAttributes } = content;
    const body = { ...restOfContentAttributes };

    body['content_image'] = content_image?.data
      ? `${
          UrlEnum.SHOHAY_STRAPI
        }${content_image?.data?.attributes?.url?.replace(/^\/uploads/, '')}`
      : null;

    return body;
  }

  private restructureComments(comment: any): any {
    const { id, ...restOfCommentAttributes } = comment?.attributes;
    const body = { ...restOfCommentAttributes };
    body['id'] = comment.id;

    return body;
  }
}
